<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="true" />
    <PageHeader
      :title="fromLotesDeuda ? `Lote N° ${loteDeudaId} - ${nombre}` : title"
    />
    <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
      <v-container>
        <v-row class="pr-4">
          <v-col cols="12" class="py-0" align="left">
            <span class="the-title text-p primary--text">{{ viewTitle }}</span>
          </v-col>
        </v-row>
        <v-row class="pr-4">
          <v-col cols="12" class="py-0" align="left">
            <FiltersSelected :filters="filtersApplyed" v-if="!showFilters" />
          </v-col>
          <v-col cols="12" align="right" align-self="center" class="py-0">
            <v-btn
              class="no-upper-case"
              text
              :disabled="fromLotesDeuda"
              @click="openModalVistas()"
              >Administrar vistas</v-btn
            >
            <v-dialog
              v-if="modalAdminVistas"
              v-model="modalAdminVistas"
              max-width="470px"
            >
              <AdministrarVistas
                :filtrosAplicados.sync="filtersSeleccionados"
                :viewTitle.sync="viewTitle"
                :viewId.sync="vistaId"
                :privateView.sync="vistaPrivada"
                :usersView.sync="vistaUsuarios"
                @closeModalVistas="closeModalVistas"
            /></v-dialog>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  :disabled="fromLotesDeuda"
                  @click.stop="closeOpenFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <v-row>
              <!-- Entidad facturante -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-autocomplete
                  v-model="entidadFacturanteSelected"
                  :items="entidadFacturante"
                  label="Entidad facturante *"
                  item-text="value"
                  @change="updateTiposComprobante()"
                  return-object
                  item-value="id"
                  :rules="rules.required"
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <!-- Convenio -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  item-text="value"
                  item-value="id"
                  multiple
                  v-model="convenioSelected"
                  return-object
                  label="Convenios"
                  :items="convenios"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="conveniosToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="convenioSelected.length > 0 ? 'primary' : ''"
                        >
                          {{ multiselectIconConvenios }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ convenioSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- Tipos de comprobante -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  item-text="value"
                  item-value="id"
                  multiple
                  v-model="tipoCombrobanteSelected"
                  return-object
                  label="Tipo de comprobante"
                  :items="tiposDeComprobantes"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="tipoComprobanteToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            tipoCombrobanteSelected.length > 0 ? 'primary' : ''
                          "
                        >
                          {{ multiselectIconTipoComprobante }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ tipoCombrobanteSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- Período desde -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-text-field
                  type="text"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  outlined
                  dense
                  hint="Formato AAAAMM"
                  label="Período desde *"
                  v-model="periodoDesde"
                  autocomplete="off"
                  :rules="rules.periodoYyyyMm.concat(rules.required)"
                ></v-text-field>
              </v-col>
              <!-- Período hasta -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-text-field
                  type="text"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  outlined
                  dense
                  hint="Formato AAAAMM"
                  label="Período hasta *"
                  v-model="periodoHasta"
                  autocomplete="off"
                  :rules="rules.periodoYyyyMm.concat(rules.required)"
                ></v-text-field>
              </v-col>
              <!-- Formas de pago -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  item-text="value"
                  item-value="id"
                  multiple
                  return-object
                  v-model="formasPagoSelected"
                  label="Forma de pago"
                  :items="formasPago"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="formasPagoToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            formasPagoSelected.length > 0 ? 'primary' : ''
                          "
                        >
                          {{ multiselectIconFormasPago }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ formasPagoSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- Entidad de cobranza -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  multiple
                  item-text="value"
                  item-value="id"
                  return-object
                  v-model="entidadCobranzaSelected"
                  label="Entidades de cobranza"
                  :items="entidadCobranza"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="entidadCobranzaToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            entidadCobranzaSelected.length > 0 ? 'primary' : ''
                          "
                        >
                          {{ multiselectIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ entidadCobranzaSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- Fecha de comprobante desde -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-menu
                  ref="menuCmp"
                  v-model="menuDesdeComprobante"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateDesdeComprobanteFormatted"
                      label="Fecha de comprobante desde"
                      :append-icon="calendarIcon"
                      hint="Formato DD/MM/AAAA"
                      @blur="
                        dateDesdeComprobante = parseDateToIso(
                          computedDateDesdeComprobanteFormatted
                        )
                      "
                      :rules="
                        computedDateDesdeComprobanteFormatted
                          ? rules.validDate
                          : []
                      "
                      dense
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDesdeComprobante"
                    @change="
                      computedDateDesdeComprobanteFormatted = formatDate(
                        dateDesdeComprobante
                      )
                    "
                    no-title
                    scrollable
                    @input="menuDesdeComprobante = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Fecha de comprobante hasta -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-menu
                  ref="menu2"
                  v-model="menuHastaComprobante"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateHastaComprobanteFormatted"
                      label="Fecha de comprobante hasta"
                      :append-icon="calendarIcon"
                      hint="Formato DD/MM/AAAA"
                      @blur="
                        dateHastaComprobante = parseDateToIso(
                          computedDateHastaComprobanteFormatted
                        )
                      "
                      clearable
                      dense
                      outlined
                      :rules="
                        computedDateHastaComprobanteFormatted
                          ? rules.validDate
                          : []
                      "
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateHastaComprobante"
                    @change="
                      computedDateHastaComprobanteFormatted = formatDate(
                        dateHastaComprobante
                      )
                    "
                    no-title
                    scrollable
                    @input="menuHastaComprobante = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Añadir comprobantes de otro lote -->
              <v-col cols="12" sm="6" class="py-0">
                <v-switch
                  class="py-0"
                  v-model="addComprobantesExistentes"
                  label="Incluir comprobantes existentes en otro lote."
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                elevation="2"
                small
                :disabled="!isFormValid"
                type="submit"
                form="filters-form"
              >
                Aplicar
              </v-btn>
            </v-row>
            <v-col class="py-0" align="left">
              <v-subheader>(*) Datos obligatorios</v-subheader>
            </v-col>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        item-key="compId"
        v-model="selected"
        show-select
        :items="resultsLoteDeuda"
        :loading="loading"
        class="elevation-1"
        :search="search"
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-simple-checkbox
                :value="isSelected"
                v-bind="attrs"
                v-on="on"
                color="primary"
                @input="select($event)"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
            <span>Seleccionar comprobante</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.saldoTotal`]="{ item }">
          <span>{{ addDecimals(item.saldoTotal) }}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="modalComprobante(item.compId)"
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver comprobante</span>
            <v-dialog
              v-if="modalCmp === true"
              v-model="modalCmp"
              max-width="80%"
            >
              <DetalleComprobante
                :compId="compId"
                @closeModalComprobante="closeModalComprobante"
              />
            </v-dialog>
          </v-tooltip>
          <v-tooltip right v-if="editLoteDeuda">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="quitarComprobante(item.compId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Quitar comprobante</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-col
        cols="11"
        align="end"
        class="pr-15"
        v-if="fromLotesDeuda === false"
      >
        <vue-excel-xlsx
          :data="resultsLoteDeuda"
          :columns="columns"
          :filename="'Resultado de la consulta'"
          :sheetname="'Comprobantes'"
          class="to-right"
        >
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :disabled="
                  resultsLoteDeuda.length > 0
                    ? listaComprobantes
                    : !listaComprobantes
                "
                class="pr-4 pt-1"
                size="30px"
                v-bind="attrs"
                v-on="on"
                color="primary"
                >{{ excelIcon }}</v-icon
              >
            </template>
            <span> Exportar a Excel </span>
          </v-tooltip>
        </vue-excel-xlsx>
      </v-col>
      <v-col cols="1" align="end" class="pl-0">
        <v-btn
          v-if="this.fromLotesDeuda === false"
          color="primary"
          class="to-right"
          :disabled="
            this.selected.length > 0 ? listaComprobantes : !listaComprobantes
          "
          @click="newLoteDeuda()"
          >Grabar lote</v-btn
        >
      </v-col>
    </v-row>
    <v-container>
      <v-dialog
        v-if="modalSaveLoteDeuda === true"
        v-model="modalSaveLoteDeuda"
        max-width="400px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="pl-1 primary--text">Grabar lote de deuda</span>
          </v-card-title>
          <v-card-text>
            <v-form
              v-model="isSaveFormValid"
              ref="crud-form"
              id="crud-form"
              @submit.prevent="saveLoteDeuda()"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    v-model.trim="loteDeuda.nombre"
                    label="Nombre"
                    item-text="name"
                    item-value="nombre"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.maxLength(loteDeuda.nombre, 45),
                        rules.requiredTrim(loteDeuda.nombre)
                      ])
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    v-model="loteDeuda.periodo"
                    label="Período"
                    item-text="name"
                    item-value="periodoHasta"
                    dense
                    outlined
                    :rules="rules.periodoYyyyMm.concat(rules.required)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="close"> Cancelar </v-btn>
            <v-btn
              type="submit"
              :disabled="!isSaveFormValid"
              form="crud-form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container>
      <v-dialog
        v-if="whereToPush === true"
        v-model="whereToPush"
        max-width="375px"
      >
        <v-card>
          <v-card-title>
            <span class="pl-1 primary--text"
              >¿Dónde desea ser redireccionado?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="goToConsultaLotes">
              Consultar lotes
            </v-btn>
            <v-btn color="primary" text @click="goToEnviarLote">
              Enviar lote
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <DeleteDialog
      :maxWidth="'25%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmQuitarComprobante()"
    />
  </v-container>
</template>

<script>
import DetalleComprobante from "@/components/modules/cuotas/devengamientos/DetalleComprobante.vue";
import AdministrarVistas from "@/components/modules/cuotas/devengamientos/AdministrarVistas.vue";
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "CreacionLoteDeuda",
  components: {
    PageHeader,
    FiltersSelected,
    DetalleComprobante,
    GoBackBtn,
    AdministrarVistas,
    DeleteDialog
  },
  created() {
    if (this.$route.params.loteDeudaId) {
      this.loteDeudaId = this.$route.params.loteDeudaId;
      this.nombre = this.$route.params.nombre;
      this.setLoteDeuda();
      this.fromLotesDeuda = true;
      if (this.$route.params.fromEdit) {
        this.editLoteDeuda = true;
      }
    }
    this.setSelects();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllEntidadesCobranza) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllEntidadesCobranza() {
      return (
        this.entidadCobranzaSelected &&
        this.entidadCobranzaSelected.length === this.entidadCobranza.length
      );
    },
    multiselectIconFormasPago() {
      if (this.selectAllFormasPago) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllFormasPago() {
      return (
        this.formasPagoSelected &&
        this.formasPagoSelected.length === this.formasPago.length
      );
    },
    multiselectIconTipoComprobante() {
      if (this.selectAllTiposComprobante) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllTiposComprobante() {
      return (
        this.tipoCombrobanteSelected &&
        this.tipoCombrobanteSelected.length === this.tiposDeComprobantes.length
      );
    },
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConvenios() {
      return (
        this.convenioSelected &&
        this.convenioSelected.length === this.convenios.length
      );
    },
    setFiltrosAplicados() {
      return (
        (this.entidadFacturanteSelected = this.filtersSeleccionados.entFac),
        (this.convenioSelected = this.filtersSeleccionados.convenio),
        (this.periodoDesde = this.filtersSeleccionados.periodoDesde),
        (this.periodoHasta = this.filtersSeleccionados.periodoHasta),
        (this.formasPagoSelected = this.filtersSeleccionados.formasDePago),
        (this.tipoCombrobanteSelected = this.filtersSeleccionados.tiposDeComprobantes),
        (this.entidadCobranzaSelected = this.filtersSeleccionados.entidadesFinancieras),
        (this.computedDateDesdeComprobanteFormatted = this.filtersSeleccionados.fechaComprobanteDesde),
        (this.computedDateHastaComprobanteFormatted = this.filtersSeleccionados.fechaComprobanteHasta),
        (this.addComprobantesExistentes = this.filtersSeleccionados.incluirComprobantesLotes)
      );
    }
  },
  data: vm => ({
    title: enums.titles.CREACION_LOTE_DEUDA,
    seeIcon: enums.icons.SEE,
    excelIcon: enums.icons.EXPORT_EXCEL,
    titleDelete: "¿Quitar comprobante?",
    showDeleteModal: false,
    filtersApplyed: [],
    whereToPush: false,
    routeToGo: "LotesDeDeuda",
    convenioSelected: [],
    convenios: [],
    modalSaveLoteDeuda: false,
    loteDeudaId: null,
    nombre: "",
    vistaId: null,
    isSaveFormValid: false,
    selected: [],
    loading: false,
    resultsLoteDeuda: [],
    search: "",
    selectComprobantesName: "Seleccionar todos",
    loteDeuda: {},
    filtersSeleccionados: {},
    selectAllButton: false,
    viewTitle: "",
    searchIcon: enums.icons.SEARCH,
    modalAdminVistas: false,
    vistaPrivada: false,
    vistaUsuarios: [],
    headers: [
      {
        text: "Código",
        align: "start",
        sortable: false,
        value: "agectaId"
      },
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "agectaNombre"
      },
      {
        text: "Tipo",
        align: "start",
        sortable: false,
        value: "tipoComprobante"
      },
      {
        text: "N°",
        align: "start",
        sortable: false,
        value: "compSucNro"
      },
      {
        text: "Período",
        align: "start",
        sortable: false,
        value: "periodo"
      },
      {
        text: "Fecha",
        align: "start",
        sortable: false,
        value: "fecha"
      },
      {
        text: "Vencimiento",
        align: "start",
        sortable: false,
        value: "fechaVencimiento"
      },
      {
        text: "Saldo",
        align: "end",
        sortable: false,
        value: "saldoTotal"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    columns: [
      {
        label: "Código",
        field: "agectaId"
      },
      {
        label: "Nombre",
        field: "agectaNombre"
      },
      {
        label: "Tipo",
        field: "tipoComprobante"
      },
      {
        label: "N°",
        field: "compSucNro"
      },
      {
        label: "Período",
        field: "periodo"
      },
      {
        label: "Fecha",
        field: "fecha"
      },
      {
        label: "Vencimiento",
        field: "fechaVencimiento"
      },
      {
        label: "Entidad financiera",
        field: "entFinNom"
      },
      {
        label: "Forma de pago",
        field: "formaDePago"
      },
      {
        label: "Saldo",
        field: "saldoTotal"
      }
    ],
    modalCmp: false,
    listaComprobantes: false,
    entidadCobranzaSelected: [],
    entidadCobranza: [],
    formasPagoSelected: [],
    formasPago: [],
    fromLotesDeuda: false,
    calendarIcon: enums.icons.CALENDAR,
    entidadFacturanteSelected: null,
    entidadFacturante: [],
    showFilters: true,
    showFiltersSelected: false,
    isFormValid: true,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    deleteIcon: enums.icons.DELETE,
    rules: rules,
    menuDesdeComprobante: null,
    menuHastaComprobante: null,
    dateHastaComprobante: null,
    dateDesdeComprobante: null,
    computedDateDesdeComprobanteFormatted: null,
    computedDateHastaComprobanteFormatted: null,
    tipoCombrobanteSelected: [],
    periodoDesde: null,
    periodoHasta: null,
    tiposDeComprobantes: [],
    addComprobantesExistentes: false,
    menu: false,
    editLoteDeuda: false
  }),
  methods: {
    ...mapActions({
      getComprobantesLotesDeuda: "devengamientos/getComprobantesLotesDeuda",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getTiposDeComprobantes: "devengamientos/getTiposDeComprobantes",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      getLoteDeudaById: "devengamientos/getLoteDeudaById",
      getFormasDePago: "devengamientos/getFormasDePago",
      getConvenios: "afiliaciones/getConvenios",
      postLotesDeuda: "devengamientos/postLotesDeuda",
      deleteComprobanteFromLote: "devengamientos/deleteComprobanteFromLote",
      setAlert: "user/setAlert"
    }),
    resetForm() {
      this.$refs["filters-form"].reset();
      this.viewTitle = "";
      this.vistaId = null;
      this.vistaUsuarios = [];
      this.vistaPrivada = false;
      this.filtersApplyed = [];
    },
    async setLoteDeuda() {
      const response = await this.getLoteDeudaById(this.loteDeudaId);
      this.entidadFacturanteSelected = response.filtrosAplicados.entFac;
      this.convenioSelected = response.filtrosAplicados.convenio;
      this.periodoDesde = response.filtrosAplicados.periodoDesde;
      this.periodoHasta = response.filtrosAplicados.periodoHasta;
      this.formasPagoSelected = response.filtrosAplicados.formasDePago;
      this.tipoCombrobanteSelected =
        response.filtrosAplicados.tiposDeComprobantes;
      this.entidadCobranzaSelected =
        response.filtrosAplicados.entidadesFinancieras;
      this.computedDateDesdeComprobanteFormatted =
        response.filtrosAplicados.fechaComprobanteDesde;
      this.computedDateHastaComprobanteFormatted =
        response.filtrosAplicados.fechaComprobanteHasta;
      this.addComprobantesExistentes =
        response.filtrosAplicados.incluirComprobantesLotes;
      this.customizeFiltersApplied();
      this.showFilters = false;
      this.resultsLoteDeuda = response.comprobantes;
    },
    async setSelects() {
      const entFac = await this.getEntidadesFacturantes();
      this.entidadFacturante = entFac;
      const entCob = await this.getEntidadesFinancieras();
      this.entidadCobranza = entCob;
      const formasDePago = await this.getFormasDePago();
      this.formasPago = formasDePago;
      const convenios = await this.getConvenios();
      this.convenios = convenios;
    },
    async setTipoDeComprobante() {
      if (this.vistaId === null) {
        const response = await this.getTiposDeComprobantes(
          this.entidadFacturanteSelected.id
        );
        this.tiposDeComprobantes = response;
      } else {
        const response = await this.getTiposDeComprobantes(
          this.filtersSeleccionados.entFac.id
        );
        this.tiposDeComprobantes = response;
      }
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async applyFilters() {
      if (this.dateDesdeComprobante <= this.dateHastaComprobante) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          entFac: this.entidadFacturanteSelected,
          convenio: this.convenioSelected,
          periodoDesde: this.periodoDesde,
          periodoHasta: this.periodoHasta,
          formasDePago: this.formasPagoSelected,
          tiposDeComprobantes: this.tipoCombrobanteSelected,
          entidadesFinancieras: this.entidadCobranzaSelected,
          fechaComprobanteDesde: this.dateDesdeComprobante,
          fechaComprobanteHasta: this.dateHastaComprobante,
          incluirComprobantesLotes: this.addComprobantesExistentes
        };
        try {
          const response = await this.getComprobantesLotesDeuda(data);
          this.resultsLoteDeuda = response;

          if (this.resultsLoteDeuda.length > 0) {
            this.showFilters = false;
            this.showFiltersSelected = true;
          }
          this.selectAllButton = true;
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message:
            "La fecha de comprobante desde no puede ser mayor a la fecha de comprobante hasta"
        });
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.entidadFacturanteSelected) {
        this.filtersApplyed.splice(0, 1, {
          key: "entidadFacturanteSelected",
          label: this.entidadFacturanteSelected.label,
          model: this.entidadFacturanteSelected.value
        });
      }
      if (this.convenioSelected.length > 0) {
        this.filtersApplyed.splice(1, 1, {
          key: "convenioSelected",
          label: "Convenios",
          model: this.convenioSelected
        });
      }
      if (this.periodoDesde) {
        this.filtersApplyed.splice(2, 1, {
          key: "periodoDesde",
          label: "Período desde",
          model: this.periodoDesde
        });
      }
      if (this.periodoHasta) {
        this.filtersApplyed.splice(3, 1, {
          key: "periodoHasta",
          label: "Período hasta",
          model: this.periodoHasta
        });
      }
      if (this.tipoCombrobanteSelected.length > 0) {
        this.filtersApplyed.splice(4, 1, {
          key: "tipoCombrobanteSelected",
          label: this.tipoCombrobanteSelected[0].label,
          model: this.tipoCombrobanteSelected
        });
      }
      if (this.entidadCobranzaSelected.length > 0) {
        this.filtersApplyed.splice(5, 1, {
          key: "entidadCobranzaSelected",
          label: this.entidadCobranzaSelected[0].label,
          model: this.entidadCobranzaSelected
        });
      }
      if (this.formasPagoSelected.length > 0) {
        this.filtersApplyed.splice(6, 1, {
          key: "formasPagoSelected",
          label: this.formasPagoSelected[0].label,
          model: this.formasPagoSelected
        });
      }
      if (this.computedDateDesdeComprobanteFormatted) {
        this.filtersApplyed.splice(7, 1, {
          key: "computedDateDesdeComprobanteFormatted",
          label: "Fecha de comprobante desde",
          model: this.computedDateDesdeComprobanteFormatted
        });
      }
      if (this.computedDateHastaComprobanteFormatted) {
        this.filtersApplyed.splice(8, 1, {
          key: "computedDateHastaComprobanteFormatted",
          label: "Fecha de comprobante hasta",
          model: this.computedDateHastaComprobanteFormatted
        });
      }
      if (this.addComprobantesExistentes) {
        const incluirCL = "Si";
        this.filtersApplyed.splice(9, 1, {
          key: "incluirComprobantesLotes",
          label: "Incluye comprobantes existentes en otro lote",
          model: incluirCL
        });
      } else {
        const incluirCL = "No";
        this.filtersApplyed.splice(9, 1, {
          key: "incluirComprobantesLotes",
          label: "Incluye comprobantes existentes en otro lote",
          model: incluirCL
        });
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    updateTiposComprobante() {
      if (
        this.entidadFacturanteSelected !== null &&
        this.entidadFacturanteSelected !== undefined
      )
        this.setTipoDeComprobante();
    },
    modalComprobante(compId) {
      this.modalCmp = true;
      this.compId = compId;
    },
    entidadCobranzaToggle() {
      this.$nextTick(() => {
        if (this.selectAllEntidadesCobranza) {
          this.entidadCobranzaSelected = [];
        } else {
          this.entidadCobranzaSelected = this.entidadCobranza;
        }
      });
    },
    tipoComprobanteToggle() {
      this.$nextTick(() => {
        if (this.selectAllTiposComprobante) {
          this.tipoCombrobanteSelected = [];
        } else {
          this.tipoCombrobanteSelected = this.tiposDeComprobantes;
        }
      });
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.convenioSelected = [];
        } else {
          this.convenioSelected = this.convenios;
        }
      });
    },
    formasPagoToggle() {
      this.$nextTick(() => {
        if (this.selectAllFormasPago) {
          this.formasPagoSelected = [];
        } else {
          this.formasPagoSelected = this.formasPago;
        }
      });
    },
    newLoteDeuda() {
      if (this.selected.length < this.resultsLoteDeuda.length) {
        this.setAlert({
          type: "warning",
          message: "Hay comprobantes sin seleccionar"
        });
      }
      this.modalSaveLoteDeuda = true;
      this.loteDeuda = {
        nombre: this.viewTitle,
        periodo: this.periodoHasta
      };
    },
    async saveLoteDeuda() {
      const filtros = {
        entFac: this.entidadFacturanteSelected,
        convenio: this.convenioSelected,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        formasDePago: this.formasPagoSelected,
        tiposDeComprobantes: this.tipoCombrobanteSelected,
        entidadesFinancieras: this.entidadCobranzaSelected,
        fechaComprobanteDesde: this.dateDesdeComprobante,
        fechaComprobanteHasta: this.dateHastaComprobante,
        incluirComprobantesLotes: this.addComprobantesExistentes
      };
      this.selected = this.selected.map(x => x.compId);
      const data = {
        nombre: this.loteDeuda.nombre,
        periodo: this.loteDeuda.periodo,
        filtrosAplicados: filtros,
        usuarioAlta: null,
        fechaAlta: null,
        compIds: this.selected
      };
      const res = await this.postLotesDeuda(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.modalSaveLoteDeuda = false;
        this.loteDeudaId = res.data.data;
        this.whereToPush = true;
      }
    },
    goToConsultaLotes() {
      this.$router.push({ name: "LotesDeDeuda" });
    },
    goToEnviarLote() {
      this.$router.push({
        name: "EnviarLoteDeuda",
        params: {
          loteDeudaId: this.loteDeudaId,
          nombre: this.loteDeuda.nombre,
          entFacId: this.entidadFacturanteSelected.id
        }
      });
    },
    close() {
      this.modalSaveLoteDeuda = false;
    },
    closeModalComprobante() {
      this.modalCmp = false;
    },
    openModalVistas() {
      const data = {
        entFac: this.entidadFacturanteSelected
          ? this.entidadFacturanteSelected
          : null,
        convenio: this.convenioSelected,
        periodoDesde: null,
        periodoHasta: null,
        formasDePago: this.formasPagoSelected,
        tiposDeComprobantes: this.tipoCombrobanteSelected,
        entidadesFinancieras: this.entidadCobranzaSelected,
        fechaComprobanteDesde: null,
        fechaComprobanteHasta: null,
        incluirComprobantesLotes: this.addComprobantesExistentes
      };
      this.filtersSeleccionados = data;
      this.modalAdminVistas = true;
    },
    closeModalVistas() {
      this.modalAdminVistas = false;
      if (this.vistaId !== null) {
        this.setTipoDeComprobante();
        this.setFiltrosAplicados;
      }
    },
    selectAllToggle() {
      if (this.selected.length != this.resultsLoteDeuda.length) {
        this.selected = [];
        this.selected = this.resultsLoteDeuda;
        this.setAlert({
          type: "info",
          message: "Todos los comprobantes han sido seleccionados."
        });
      } else {
        this.selected = [];
        this.setAlert({
          type: "info",
          message: "Todos los comprobantes han sido deseleccionados."
        });
      }
    },
    quitarComprobante(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmQuitarComprobante() {
      const response = await this.deleteComprobanteFromLote({
        loteDeudaId: this.loteDeudaId,
        compId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setLoteDeuda();
      }
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
</style>
